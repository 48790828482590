.board_card {
    /* width: 100%;
    height: 250vh; */
    width: 18%;
    height: 60vh;
    /* height: 25%; */
    border: 4px solid #f8bc04;
    box-shadow: 0 0 10px 5px #fbdc7f;
    border-radius: 12px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    margin-top: 5vh;
    padding-bottom: 3vh;
}

.board_card img {
    height: 70%;
    width: 100%;
    object-fit: cover;
    margin-bottom: 7%;
    border-radius: 8px 8px 0 0;
}

.board_name {
    height: 10%;
    /* padding: 1%; */
    padding-bottom: 0;
}

/* .board_post {
    padding: 0.6%;
} */

.board_name p {
    color: #f8bc04;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.3rem;
}

.board_post p {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.2rem;
}

@media screen and (max-width: 768px) {
    .board_card {
        width: 45%;
    }
}

@media screen and (max-width: 576px) {
    .board_card {
      width: 80%;
      height: 50vh;
    }
}
