html {
  scroll-behavior: smooth;
  scrollbar-width: none;
  user-select: none;
}
.title {
  position: fixed;
  text-align: right;
  margin-top: 1.5%;
  width: 94%;
}

.hideTitle {
  opacity: 0;
  transition: opacity 0.7s;
  pointer-events: none;
}

.mainPage {
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: space-between;
  position: fixed;
  z-index: 1;
}

.intro {
  width: 50%;
  position: absolute;
  left: 25%;
  z-index: 2;
  display: grid;
  grid-template-rows: 100vh 100vh 100vh 100vh 100vh;
}

.sun {
  margin-left: -5%;
}

.planets {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 120%;
}

.planets a {
  width: 20%;
  text-decoration: none;
}

.planet {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.planets p {
  text-align: center;
  font-weight: bold;
}

.planets video:hover + p {
  display: block;
  width: 125%;
  font-size: larger;
  color: #f8bc04;
}

.planets video {
  width: 100%;
  transition: all 100ms ease-in-out;
  position: relative;
}

.planets video:hover {
  width: 125%;
  transition: all 100ms ease-in;
}

.cont {
  padding-top: 12vh;
  margin-left: 5%;
}

.title p {
  margin-right: 6vh;
  margin-top: 4.5vh;
  font-size: 1.5rem;
}

.footer {
  width: 100%;
  position: absolute;
  top: 500vh;
  z-index: 3;
}

/* .title img {
  width: 100%;
  rotate: 90deg;
} */

.bArrow {
  width: 8vh;
  aspect-ratio: 1/1;
  position: fixed;
  z-index: 100;
  margin-top: 85vh;
  right: 4vh;
  background: none;
  border: none;
  /* animation: blinker 2s linear infinite; */
}

/* @keyframes blinker {
  50% {
    opacity: 0;
  }
} */

.heheBoi{
  z-index: 100;
  position: relative;
  top: 20vh;
  right: 0;
  width: 100%;
}

.heheBoi img{
   width: 35vh;
   position: absolute;
  animation: moveImage 15s linear infinite;
}

@keyframes moveImage {
  0% {
      left: 100%;
  }
  100% {
      left: -35vh;
  }
}

.upcEvents{
  position: absolute;
  z-index: 1000;
  width: 65vw;
  height: 70vh;
  left: 52%;
  top: 20%;
  transform: translate(-50%);
  background-color: #d3d3d3;
  color: rgb(49, 49, 221);
  text-align: center;
  border-radius: 15px;
}
.upcEvents button{
  background: none;
  border: none;
  position: absolute;
  right: 0;
  width: 3%;
  border-radius: 15px;
  aspect-ratio: 1/1;
}
.upcEvents button:hover{
  rotate: 90deg;
  transition: rotate 0.2s ease;
}

.upcEvents h3{
  margin-block: 2%;
}
.upcEventsCont{
  display: flex;
  margin-inline: 2%;
margin-top: 3%;
}
.upcEventsCont p{
  margin-inline: 2%;
  color: #6b45b7;
}

.upcEventsCont img{
  width: 90%;
  height: 25vh;
  margin-block: 3%;
}

@media screen and (max-width: 576px) {
  .mainPage, .bArrow,.title img, .heheBoi, .upcEvents{
    display: none;
  }
  .title p {
    margin: 0;
    font-size: larger;
  }
  .title {
    text-align: center;
    margin: 0;
    padding-block: 1rem 1rem;
    width: 100%;
    z-index: 4;
    background-color: rgba(0, 0, 0, 0.9);
  }
  .hideTitle {
    opacity: 1;
    transition: none;
  }
  .planets {
    flex-direction: column;
    width: 100%;
  }
  .intro {
    width: 100%;
    top: 5rem;
    left: 0;
    grid-template-rows: 150dvh 80dvh 50dvh 50dvh 50dvh; 
  }

  .intro section,
  .intro .introComp {
    width: 100%;
  }
  .cont {
    margin-left: 0;
    padding: 0;
    width: 94%;
    margin-inline: 3%;
  }
  .planets a {
    width: 50%;
  }
  .footer{
    top: 390dvh;
  }
}

@media screen and (max-width: 1200px) and (min-width: 768px) {
  .sun {
    margin-left: -25%;
  }
  .planets {
    flex-wrap: wrap;
    width: 70%;
    aspect-ratio: 1/1;
    margin-top: 20vh;
     margin-left: 25%;
    justify-content: space-around;
    align-items: space-around;
  }
  .planets a {
    flex: 1 0 50%;
  }
  .title {
    text-align: center;
    width: 100%;
    margin-inline: 0;
  }
  .mainPage {
    display: flex;
    height: 100vh;
    width: 90%;
    justify-content: space-between;
    z-index: 1;
  }
  .intro {
    width: 70%;
    overflow: hidden;
    left: 10%;
  }
  .planets video:hover {
    width: 90%;
  }
  .upcEvents{
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
    height: 72vh;
  }
  .upcEvents button{
    width: 5%;
  }
}
@media screen and (max-width: 768px) and (min-width: 576px){
  .mainPage{
    display: none;
  }
}