.eventCard {
    border: 4px solid #f8bc04;
    box-shadow: 0 0 10px 5px #fbdc7f;
    border-radius: 12px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    margin-bottom: 8%;
    padding-bottom: 2.5vh;
    position: relative;
}

.eventCard img {
    height: 90%;
    width: 100%;
    z-index: 1;
    object-fit: cover;
    margin-bottom: 2%;
    border-radius: 8px;
}

.eventDisc{
    position: absolute;
    height: 90%;
    width: 70%;
    margin-left: 15%;
    top: 0;
    display: flex;
    align-items: center;
    margin-bottom: 2%;
    border-radius: 8px;
    line-height: 2;
    font-size: 1.2rem;
}

.eventName {
    height: 10%;
    padding-bottom: 2.5vh;
    margin-top: 1%;
}

.eventName p {
    color: #f8bc04;
    margin-bottom: 0;
}

.eventName button{
    background: none;
    border: none;
}

.hideImg{
    opacity: 0.2;
    transition: all 1s ease;
}

.hideDesc{
    opacity: 0;
    transition: all 1s ease;
}

.showImg {
    opacity: 1;
    transition: all 1s ease;
}

@media screen and (max-width: 576px) {
    .eventCard{
      padding-bottom: 10%;
    }
    .eventName{
        width: 95%;
        margin-inline: 2.5%;
        height: 25%;
    }
    .eventName button{
        user-select: none;
        height: 100%;
        width: 100%;
        touch-action: manipulation;
    }
    .eventDisc{
        font-size: small;
        width: 90%;
        margin-inline: 5%;
        user-select: none;
    }
    .eventCard img, .eventDisc{
        margin-bottom: 2%;
    }
}