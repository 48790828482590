@import url("https://fonts.googleapis.com/css2?family=Michroma&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Audiowide&family=Michroma&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Michroma", sans-serif;
  color: white;
}

.logo img {
  width: 5rem;
  margin-top: 15%;
  /* margin-right: 10%; */
}

/* .logo {
  display: flex;
  justify-content: centre;
  align-items: center;
} */

.nvbar ul {
  display: flex;
  flex-direction: column;
  height: 80%;
  justify-content: center;
}
.nvbar ul a {
  text-decoration: none;
  text-transform: capitalize;
  font-size: 1.5rem;
  margin-top: 5%;
}

.nvbar_p,
.nvbar {
  transition: opacity 0.7s, transform 0.7s;
  opacity: 1;
}

.hidenav {
  /* display: none; */
  opacity: 0;
  pointer-events: none;
}

.vertnav {
  height: 100vh;
  width: 15%;
  text-align: end;
  margin-right: 2%;
  display: grid;
  grid-template-rows: 1fr 6fr;
}

a {
  color: white;
  text-decoration: none;
}

/* .active {
    position: relative;
    display: inline-block;
}

.active:after {
    content: "";
    position: absolute;
    right:0;
    top: 100%;
    width: 50%;
    height: 4px;
    background-color: #f8bc04;
}

a {
    position: relative;
    display: inline-block;
}

a:after {
    content: "";
    position: absolute;
    right:0;
    top: 100%;
    width: 0;
    height: 2px;
    background-color: #fbdc7f;
    transition: width .3s ease-in-out;
}
    
a:hover:after {
    width: 50%;
} */

.active {
  color: #f8bc04;
}

a:hover {
  color: #fbdc7f;
}
