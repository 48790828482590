.eventspage{
    position: relative;
    top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.eventCards{
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4%;
}

.foot{
  width: 100%;
}

@media screen and (max-width: 768px) {
    .eventCards {
      grid-template-columns: 1fr;
    }
  }