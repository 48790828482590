.horinav {
  z-index: 2;
  background-color: black;
  position: fixed;
  top: 0;
  display: grid;
  grid-template-columns: 5fr 3fr;
  width: 100%;
  height: 4rem;
}

.horinav ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: auto;
}

.horinav p {
  font-weight: bold;
  margin-block: auto;
  font-size: large;
}

.horinav a {
  font-weight: bold;
}

.horinav img {
  width: 3rem;
  margin-inline: 2%;
}

.horilogo {
  display: flex;
  justify-content: end;
  height: 100%;
  align-items: center;
}

.hamenu{
  display: none;
}

@media screen and (max-width: 576px){
  .horinav ul{
    display: none;
  }
  .horilogo{
    flex-direction: row-reverse;
    justify-content: start;
  }
  .hamenu{
    display: flex;
    justify-content: end;
    align-items: center;
    margin-right: 15%;
  }
  .hamenu div{
    background-color: black;
  }
  .hamenu a{
    color: white;
  }
}