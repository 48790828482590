footer {
  height: 90px;
  border-radius: 100px 100px 0 0;
  border-top: 2px solid orange;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.footerRow {
  width: 60%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* align-items: center;
  justify-content: space-around; */
  font-size: small;
}
#footlogo {
  width: 11%;
  font-weight: 1000;
  font-size: 1.2rem;
}
.dev{
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: small;
}
footer a {
  font-weight: 400;
}

.connectUs {
  display: flex;
  justify-content: space-around;
}

.links {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

footer div p {
  font-size: medium;
  font-variant: small-caps;
  font-style: italic;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: wheat;
  font-weight: bold;
  margin-top: 7%;
}
.stellarCon{
  margin-block: 0 5%;
}
.tab{
display: none;
}

@media screen and (max-width: 576px){
  footer{
    flex-direction: column;
    border-radius: 20px 20px 0 0;
    background: none;
  }
  .footerRow{
    grid-template-columns: 1fr;
    margin-block: 3%;
  }
  .connectUs{
    margin-bottom: 10%;
  }
  #footlogo{
    width: 80%;
    text-align: center;
    margin-top: 2%;
  }
}
@media screen and (max-width: 1200px) and (min-width: 768px) {
  .footerRow{
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 10px;
  }
  .footerRow :first-child{
    grid-area: 1/1/2/4 ;
    margin-bottom: 2%;
  }
  footer{
    border-radius: 20px 20px 0 0;
  }
  .deskP{
    display: none;
  }
  .dev .tab{
    display: contents;
  }
}