.introComp{
    background-color: rgba(0, 0, 0, 0.5);
    width: 110%;
    margin: auto;
    margin-top: 5%;
    border: 4px solid #f8bc04;
    box-shadow: 0 0 10px 5px #fbdc7f;
    border-radius: 12px;
    text-align: center;
    position: relative;
}

.introComp h1{
    text-decoration: underline;
    text-transform: uppercase;
    padding: 2%;
    font-family: 'Audiowide', sans-serif;
    display: inline-block;
}

.introComp p{
    padding: 1%;
    font-size: large;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: x-large;
}

.carousel-item img {
    height: 400px;
    object-fit: cover;
}

h4 {
    text-shadow:
    2px 2px 1px #000,
    -2px 2px 1px #000,
    -2px -2px 0 #000,
    2px -2px 0 #000;
}

.nl a{
    width: 25%;
    position: absolute;
    display: flex;
    flex-direction: column;
}

.nl p{
    margin-top: 4%;
}

.nl h1 {
    text-decoration: underline;
    text-transform: uppercase;
    padding: 2%;
    font-family: 'Audiowide', sans-serif;
    display: inline-block;
}

#pic1 {
    top: 25%;
    right: 36.5%;
}

#pic2 {
    right: 5%;
    top: 20%;
    filter: brightness(30%);
}

#pic3 {
    left: 5%;
    top: 20%;
    filter: brightness(30%);
}

.nl {
    background-color: rgba(0, 0, 0, 0.5);
    width: 110%;
    margin: auto;
    margin-top: 5%;
    border: 4px solid #f8bc04;
    box-shadow: 0 0 10px 5px #fbdc7f;
    border-radius: 12px;
    text-align: center;
    height: 60vh;
    position: relative;
}

@media screen and (max-width: 576px){
    #pic2,#pic3{
        top: 20%;
    }
    #pic1{
        top: 40%;
        left: 35%;
        width: 30%;
    }
    .introComp p{
        font-size:larger; 
    }
    .nl {
        width: 100%;
        height: 40vh;
    }
    .vit{
        padding-top: 5%;
    }
}

.vit {
    position: absolute;
    top: 18%;
}

.visible {
    opacity: 100;
    transition: all 1s;
}

.hidden {
    opacity: 0;
    transition: all 1s;
}

.pointer {
    cursor: pointer;
}

.clicked {
    font-size: 36px;
    transition: all 1s ease;
}

.notClicked {
    font-size: 26px;
    transition: all 1s ease;
}

@media screen and (max-width: 1200px) and (min-width: 768px) {
    .introComp, .nl{
        width: 75%;
        height: 70vh;
        overflow-y: auto;
        scrollbar-width: thin;
        scroll-behavior: smooth;
    }
    .introComp h1, .nl h1{
        padding-top: 3%;
    }
    #pic2{
       bottom: 3%;
       top: auto;
    }
    #pic1{
        top: 35%;
        bottom: auto;
    }
    .clicked{
        font-size: larger;
        color: #f8bc04;
    }
    .notClicked{
        font-size: medium;
    }
}