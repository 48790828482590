.newspdf {
  position: relative;
  top: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.newspdf h1 {
  margin-bottom: 5vh;
}

.newspdf img {
  width: 80%;
  margin-bottom: 5vh;
  border: 4px solid #f8bc04;
  box-shadow: 0 0 5px 5px #fbdc7f;
}