.content {
    position: relative;
    top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.group {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    padding: 5%;
    padding-top: 0;
    padding-bottom: 0;
}

.img {
    width: 100%;
    height: 50vh;
    border: 4px solid #f8bc04;
    box-shadow: 0 0 10px 5px #fbdc7f;
    border-radius: 12px;
    margin-top: 5vh;
}

.img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.desc p {
    width: 100%;
    margin-top: 5vh;
    margin-bottom: 0;
    text-align: center;
    font-size: 1.5rem;
    font-family: "IBM Plex Sans", sans-serif;
}

.cards_cont {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding: 5%;
    padding-top: 0;
    padding-bottom: 0;
}

h2 {
    margin-top: 12.5vh;
}

.coord {
    width: 90%;
    border: 4px solid #f8bc04;
    box-shadow: 0 0 10px 5px #fbdc7f;
    border-radius: 12px;
    padding-inline: 5%;
    padding-block: 2%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 4vh;
    margin-bottom: 6vh;
    background-color: rgba(0, 0, 0, 0.5);
}

.coord_pic {
    width: 20%;
    height: 100%;
    float: left;
    text-align: center;
}

.coord_pic img {
    object-fit: cover;
    margin-bottom: 5%;
    height: 100%;
    width: 100%;
}

.coord_pic p {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
}

.coord_desc {
    width: 75%;
    float: right;
    margin-left: 5%;
    margin-top: 5%;
    height: fit-content;
}

.coord_desc p {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
    .coord {
        width: 85%;
    }
    .coord_pic {
        width: 100%;
    }
    .coord_desc {
        width: 100%;
        /* padding-top: ; */
    }
    .img {
        height: fit-content;
    }
    .img img {
        object-fit: scale-down;
    }
}
